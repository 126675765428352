import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { StaticImage } from "gatsby-plugin-image";
import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { navigate } from "gatsby";

const theme = createTheme({
  palette: {
    background: {
      default: "#c3b5d7",
    },
  },
});

export default function SignIn() {
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const password = data.get("password");
    const confirmPassword = data.get("confirmPassword");
    let error: string | null = "";
    if (!password) {
      setErrorMessage("Попълнете Парола.");
      setOpenAlert(true);
      return;
    }
    if (!confirmPassword) {
      setErrorMessage("Попълнете Повтори Парола.");
      setOpenAlert(true);
      return;
    }
    if (confirmPassword !== password) {
      setErrorMessage(
        "Полетата 'Парола' и 'Повтори Парола' трябва да са еднакви"
      );
      setOpenAlert(true);
      return;
    }
    if (error.length > 0) {
      setErrorMessage(error);
      setOpenAlert(true);
    }
    const queryParams = new URLSearchParams(document.location.search);
    const token = location.search.substring(
      location.search.indexOf("token=") + 6
    );
    const userId = queryParams.get("userId");
    const url: string = `https://zubcheto-eo.com/api/identity/resetPassword`;
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: "" },
      body: JSON.stringify({
        resetToken: token,
        userId: userId,
        newPassword: password,
        confirmNewPassword: confirmPassword,
      }),
    };
    fetch(url, requestOptions)
      .then((res) => {
        if (!res.ok) {
          return res.json();
        }
        navigate("/SuccessResetPassword/");
        return;
      })
      .then((res) => {
        if (res) {
          handleFailure(res);
        }
      });
  };

  const handleFailure = (err: any) => {
    const error = getStringFromValidationError(err);
    setErrorMessage(error);
    setOpenAlert(true);
  };

  const handleClose = () => {
    setOpenAlert(false);
    setErrorMessage(null);
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Dialog
          open={openAlert}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Грешка</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Затвори</Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <StaticImage
            src="../images/avatar-settings.png"
            alt="Zubcho Settings"
            placeholder="blurred"
            layout="fixed"
            width={200}
          />
          <Typography component="h1" variant="h5" sx={{ pt: 4 }}>
            Забравена парола
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Парола"
              name="password"
              type="password"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Повтори парола"
              type="password"
              id="confirmPassword"
            />
            <Button
              style={{ backgroundColor: "#4D475F" }}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Смени паролата
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

const getStringFromValidationError = (errorsModel: any): string => {
  const errorsArray: string[] = [];

  errorsModel.errors.forEach((error: any) => {
    error.errorMessages.forEach((errorMessage: any) => {
      errorsArray.push(errorMessage);
    });
  });

  const result = "\n" + errorsArray.filter(onlyUnique).join("\n");

  return result;
};

function onlyUnique(value: any, index: any, self: any) {
  return self.indexOf(value) === index;
}
